import React, { useEffect, useMemo, useState } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { v4 as uuid } from 'uuid';
import { VendorMapping } from '../../landing-page/landing-page-types';
import { useAlert } from '../../../api/alert-api/AlertAPI';
import {
  getVendorFromVendorId,
  getVendorSettingsColumns,
  watchVendorMappings,
} from '../../../api/settings-api/SettingsAPI';
import { DeleteVendorNamesModal } from './DeleteVendorNamesModal';
import { VendorId } from '../settings-page-types';

/**
 * Vendor settings component that allows user to change fields allowed of a vendor.
 */
export const VendorSettings = function ({ csvVendorNames }:{ csvVendorNames?: string[] }) {
  const [vendorMappings, setVendorMappings] = useState<VendorMapping[]>([]);
  // the focused vendor id is the vendor which will populate the Delete Vendor Matches modal
  const [focusedVendorId, setFocusedVendorId] = useState<VendorId | null>(null);

  // if vendor names have been passed, we should filter to only show vendors which includes those vendor name matches
  const visibleVendorMappings = csvVendorNames
  // here we check if at least one of the vendor names mapped to this vendor is in the list of csvVendorNames to filter by
    ? vendorMappings.filter((mapping) => mapping.csvVendorName.some((name) => csvVendorNames.includes(name)))
    : vendorMappings;

  const updateVendorMappings = (mappings: VendorMapping[]) => setVendorMappings(mappings);
  const updateFocusedVendor = (vendor: VendorId | null) => setFocusedVendorId(vendor);

  const alert = useAlert();
  const columns = useMemo(
    () => getVendorSettingsColumns(alert, updateFocusedVendor),
    [alert, updateFocusedVendor],
  );

  /**
   * Watcher for vendor mappings Firestore doc
   */
  useEffect(() => {
    const unsubscribe = watchVendorMappings(updateVendorMappings);
    return () => unsubscribe();
  }, []);

  // using the focused vendor Id, retrieve the focused vendor from vendorMappings
  const focusedVendor = useMemo(
    () => (focusedVendorId ? getVendorFromVendorId(vendorMappings, focusedVendorId) : null),
    [vendorMappings, focusedVendorId],
  );

  return (
    <>
      <DataGridPro
        className="h-full"
        columns={columns}
        rows={visibleVendorMappings}
        getRowId={() => uuid()}
      />
      {focusedVendor && (
      <DeleteVendorNamesModal
        focusedVendor={focusedVendor}
        updateFocusedVendor={updateFocusedVendor}
      />
      )}
    </>
  );
};
