// represents a file retrieved from Drive
export interface GoogleDriveFileData<T> {
  name: string,
  data: T
}

// includes all data related to a single unit, including its property code
export interface UnitData {
  yardiPropertyCode: string,
  yardiUnitCode: string,
  entrataUnitData: EntrataUnitData
  entrataPropertyCode: string
}

// maps Yardi unit code to Entrata unit Id
export interface UnitCodeMapping {
  yardiUnitCode: string,
  entrataUnitCode: string
}

// represents most recent files for divvy or ramp, retrieved from GDrive
export interface MostRecentFiles {
  importsFile: GoogleDriveFileData<CsvRow[]>
  imagesZipFileName?: string
}

// Mapping of data relating to a property, including its units. Maps Yardi prop code to Entrata prop ID.
export interface PropertyCodeData {
  yardiPropertyCode: string,
  entrataPropertyCode: string
  units: UnitCodeMapping[]
}

// Entrata data related to a unit.  The unit number is the reference name of the unit e.g. 208A,
// while the id is the unit's id within Entrata.
export interface EntrataUnitData {
  unitNumber: string,
  unitId: string
  buildingName: string
}

// Mapping of entrata property to its units.
export interface EntrataUnitCodeMapping {
  [entrataPropertyCode: string]: EntrataUnitData[]
}

// data sources for invoice data
export enum DataSource {
  RAMP = 'Ramp',
  DIVVY = 'Divvy',
}

export interface CsvRow {
  [key: string]: string
}

// mapping of vendor names in Imports CSV to entrata vendor data
export interface VendorMapping {
  csvVendorName: string[],
  entrataVendor: VendorData | null
  useOriginalInvoiceNumber: boolean
}

// represents the Firestore doc which contains vendor mappings
export interface VendorMappingDoc {
  mappings: VendorMapping[]
}

// temporary data structure used to hold mapping made by user
export interface VendorMatch {
  csvVendorName: string,
  entrataVendor: VendorData | null
  useOriginalInvoiceNumber: boolean
}

// Mapping component maps these different types of data
export enum MappingType {
  PROPERTY = 'Property',
  UNIT = 'Unit',
  VENDOR = 'Vendor',
}

// Entrata vendor data
export interface VendorData {
  vendorName: string,
  locationId: string,
  vendorId: string
  vendorCode: string
}

// Represents collections in Firestore
export enum FirestoreCollections {
  CODE_MAPPINGS = 'codeMappings',
  CODES = 'codes',
  UPLOAD_RUNS = 'uploadRuns',
  HEADERS = 'headers',
}

// Represents docs in Firestore
export enum FirestoreDocs {
  PROPERTY_MAPPINGS = 'propertyMappings',
  VENDOR_MAPPINGS = 'vendorMappings',
  VENDOR_CODES = 'vendorCodes',
  RUN_STATUSES = 'runStatuses',
  IMPORTS_CSV = 'importsCsv',
}

// represents required headers in Divvy imports CSV
export interface DivvyHeaders {
  TransactionId: string
  SplitFrom: string
  InvoiceDate: string
  VendorName: string
  Amount: string
  InvoiceFileName: string
  Description: string
  YardiPropertyCode: string
  GlAccountSubstring: string
  ClearedDate: string
  UnitNumber: string
  InvoiceNumber: string
}

// represents required headers in Ramp imports CSV
export interface RampHeaders {
  InvoiceDate: string
  VendorName: string
  HasReceipt: string
  Description: string
  TransactionId: string
  ClearedDate: string
  Amount: string
  GlAccount: string
  YardiPropertyCode: string
}

// represents headers for both data sources
export interface HeadersDoc {
  divvy: DivvyHeaders
  ramp: RampHeaders
}

// represents a unit code and its parent property code
export interface UnitCode {
  propertyCode: string,
  unitCode: string
}

// represents an entrata property returned from the getProperties API (Entrata)
export interface EntrataProperty {
  propertyId: string,
  propertyName: string
  yardiCode: string
}

// represents Firestore doc which stores vendor data pulled from Entrata
export interface VendorsDoc {
  codes: VendorData[]
}
