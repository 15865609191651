import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyBjllzZ_DbiARVZNPDNuQQdqhLdJxmHmhw',
  authDomain: 'wreg-entrata-divvy-ramp-patch.firebaseapp.com',
  projectId: 'wreg-entrata-divvy-ramp-patch',
  storageBucket: 'wreg-entrata-divvy-ramp-patch.appspot.com',
  messagingSenderId: '352607774417',
  appId: '1:352607774417:web:637fd7a3d9e293133847f0',
  measurementId: 'G-7SSBFZNFDD',
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const functions = getFunctions();
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
