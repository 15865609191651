import { collection, onSnapshot } from 'firebase/firestore';
import { utils, WorkBook, write } from 'xlsx';
import dayjs from 'dayjs';
import { db } from '../../../firebase-config';
import {
  InvoiceUploadStatus,
  InvoiceUploadStatuses,
  RunStatus, UploadRun,
  UploadsStatusesDoc,
  UploadStatus,
} from '../../pages/statuses-page/statuses-page-types';
import { DataSource } from '../../pages/landing-page/landing-page-types';

/**
 * Watcher for uploadStatuses doc in Firestore.
 * @param updateRunStatuses helper function to setRunStatuses
 */
export const watchUploadStatuses = (
  updateRunStatuses: (data: UploadStatus[]) => void,
) => onSnapshot((collection(db, 'uploadRuns')), (runsCollection) => {
  const statuses: UploadStatus[] = [];

  runsCollection.forEach((document) => {
    const docData = document.data() as UploadRun;
    if (!docData) return;
    statuses.push([document.id, docData]);
  });
  // sort runs by timestamp in descending order so that most recent runs are first
  const sortedStatuses = statuses.sort((a, b) => b[1].runStartTimestamp - a[1].runStartTimestamp);
  updateRunStatuses(sortedStatuses);
});

/**
 * Filter run statuses by dataSource.
 * @param selectedDataSource divvy/ramp
 * @param runStatuses all run statuses
 */
export const getVisibleRunStatuses = (
  selectedDataSource: DataSource,
  runStatuses: UploadStatus[],
) => runStatuses.filter(({ 1: uploadRun }) => {
  // divvy statuses will always have an images Zip filename, whereas ramp statuses will never include an imagesZip filename
  const hasImagesZip = !!uploadRun.fileNames.imagesZip;
  if (selectedDataSource === DataSource.DIVVY) return hasImagesZip;
  return !hasImagesZip;
});

/**
 * Returns a breakdown of how many uploads fall under each status category.
 * @param statuses upload statuses
 */
export const getStatusBreakdown = (statuses: InvoiceUploadStatuses) => {
  const statusTypes = Object.values(statuses);
  const uploadsPending = statusTypes.filter(({ status }) => status === RunStatus.PENDING).length;
  const uploadsFailed = statusTypes.filter(({ status }) => status === RunStatus.FAILURE).length;
  const uploadsSuccessful = statusTypes.filter(({ status }) => status === RunStatus.SUCCESS).length;
  return { uploadsFailed, uploadsSuccessful, uploadsPending };
};

/**
 * Adds a sheet to the Statuses excel file.  Each sheet contains all the transactions at a given status.
 * @param wb Statuses workbook/xlsx
 * @param statuses all statuses
 * @param filterStatus status type to filter for in this sheet
 */
export const addSheetToStatusesWorkbook = (
  wb: WorkBook,
  statuses: [string, InvoiceUploadStatus][],
  filterStatus: RunStatus,
) => {
  // the text to display in the sheet if there are no uploads for a given sheet/status
  const emptyText = ['No uploads under this status'];
  const uploads = statuses
  // we use index 1 as that is where the status is stored in the entry
    .filter((status) => status[1].status === filterStatus)
    .map((status) => ({
      'Transaction Id': status[0],
      'Vendor Name': status[1].vendorName,
      Amount: status[1].amount,
      'Property Code': status[1].propertyCode,
    }));
  // if there is no data, we still want to include the headers
  const sheet = uploads.length ? utils.json_to_sheet(uploads) : utils.aoa_to_sheet([emptyText]);
  utils.book_append_sheet(wb, sheet, filterStatus);
};

/**
 * Generates downloadable xlsx from upload statuses.
 * @param uploadRun invoice upload statuses
 */
export const generateStatusesXlsx = (uploadRun: UploadStatus) => {
  const wb = utils.book_new();
  const statuses = Object.entries(uploadRun[1].statuses);
  // Generates a sheet for each status type
  Object.values(RunStatus).forEach((runStatus) => addSheetToStatusesWorkbook(wb, statuses, runStatus));
  return write(wb, { bookType: 'xlsx', type: 'array' }) as Buffer;
};

/**
 * Generates and download an XLSX file of all upload statuses for a given run.
 * @param uploadRun the run from which we are download all statuses
 */
export const downloadStatusesXlsx = (uploadRun: UploadStatus) => {
  // generates xlsx
  const xlsxBuffer = generateStatusesXlsx(uploadRun);
  const blob = new Blob([xlsxBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = window.URL.createObjectURL(blob);
  // generate a more informative filename for the xlsx file
  const readableStartTimestamp = dayjs.unix(uploadRun[1].runStartTimestamp).format('MM-DD-YYYY-HH_mm_ss');
  const filename = `Statuses-${uploadRun[1].fileNames.imports}-${readableStartTimestamp}.xlsx`;
  // create temp download link and click it
  const downloadLink = document.createElement('a');
  downloadLink.href = url;
  downloadLink.download = filename;
  downloadLink.click();
  // Clean up by revoking the URL
  window.URL.revokeObjectURL(url);
};

/**
 * Gets total of all amounts in a given uploadRun.
 * @param runData data related to an uploadRun
 */
export const getRunAmountTotal = (runData: UploadRun) => Object.values(runData.statuses)
  .map((status) => status.amount)
  .reduce((acc, amount) => {
    // we use abs val as the amounts can be negative
    const parsedAmount = parseFloat(amount.replace(/[$,()]/g, ''));
    return acc + parsedAmount;
  }, 0).toFixed(2);
