// represents the status of an attempted invoice upload
export enum RunStatus {
  FAILURE = 'Failure',
  PENDING = 'Pending',
  SUCCESS = 'Success',
}

// represents file names of invoice files from Drive
export interface FileNames {
  imports: string,
  imagesZip: string
}

// represents information detailing an upload status for a given transaction
export interface InvoiceUploadStatus {
  status: RunStatus,
  vendorName: string,
  propertyCode: string,
  amount: string
}

// represents upload statuses for individual invoice uploads where transactionId (corresponds to upload) is mapped to status
export interface InvoiceUploadStatuses {
  [transactionId: string]: InvoiceUploadStatus
}

// represents uploadsRun object from doc in Firestore that stores all upload statuses for a given run
export interface UploadRun {
  runStartTimestamp: number,
  fileNames: FileNames
  statuses: InvoiceUploadStatuses
}

// represents uploadRuns mapping from Firestore that contains all statuses of all upload runs, indexed by runId
export interface UploadStatusMapping {
  [runId: string]: UploadRun
}

// represents uploadsRun doc from Firestore
export interface UploadsStatusesDoc {
  runs: UploadStatusMapping
}

// represents upload status where [runId, uploadRun]
export type UploadStatus = [string, UploadRun];
