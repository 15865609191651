import React from 'react';
import { PageType } from '../../api/navigation-api/NavigationTypes';
import { ApplicationContainer } from '../../helper';
import { VendorSettings } from './vendor-settings/VendorSettings';

/**
 * Page which allows user to change settings related to upload process.
 */
export const SettingsPage = function () {
  return (
    <ApplicationContainer focusedPage={PageType.Settings} className="bg-gray-50 h-[100vh] w-[100vw]">
      <div className="ml-44 font-inter flex flex-col">
        <div className="text-5xl font-semibold mt-10 mb-20">Settings</div>
        <div className="bg-white border-gray-200 border-[1px] h-[70vh] w-[85vw] rounded-xl p-8">
          {/*  Vendor-related settings */}
          <VendorSettings />
        </div>
      </div>
    </ApplicationContainer>
  );
};
