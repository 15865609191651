import { Button } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import React, { useMemo } from 'react';
import { VendorSettings } from '../settings-page/vendor-settings/VendorSettings';
import { ReviewVendorSettingsParams } from './landing-page-params';

export const ReviewVendorSettings = function ({
  updateVendorSettingsReviewed,
  divvyFiles,
  csvHeaders,
}: ReviewVendorSettingsParams) {
  // returns all the vendor names from the divvy imports csv (if divvy)
  const divvyCsvVendorNames = useMemo(
    () => divvyFiles.importsFile.data.map((row) => row[csvHeaders.divvy.VendorName]),
    [divvyFiles, csvHeaders],
  );

  return (
    <>
      {/* Vendor settings datagrid - we filter for the relevant settings by passing in the vendor names */}
      <VendorSettings csvVendorNames={divvyCsvVendorNames} />
      {/* Continue button */}
      <div className="flex flex-row justify-end">
        <Button
          className="bg-blue-500 text-white hover:bg-blue-400 hover:text-white rounded-xl mt-12"
          endIcon={<ArrowForward />}
          onClick={() => { updateVendorSettingsReviewed(true); }}
        >
          Continue
        </Button>
      </div>
    </>
  );
};
