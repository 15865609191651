import { Autocomplete, TextField } from '@mui/material';
import React, { useMemo } from 'react';
import { getUnitCodeOptions } from '../../../api/landing-page-api/LandingPageAPI';
import { UnitMappingRowParams } from '../landing-page-params';
import { EntrataUnitData, UnitData } from '../landing-page-types';
import { alertError } from '../../../api/error-api/ErrorAPI';
import { useAlert } from '../../../api/alert-api/AlertAPI';

/**
 * Row used to map a Yardi unit code to an Entrata unit.
 * @param mapping property code mapping (contains units)
 * @param existingPropertyMappings existing property mappings pulled from Firestore
 * @param paginatedUnitCodeMaps paginated batch of unit code mappings
 * @param entrataUnitCodes list of entrata unit code options
 * @param loadingUnitCodes whether unit codes are loading (getting pulled from entrata)
 * @param updatePaginatedUnitCodeMaps helper to setPaginatedUnitCodeMaps
 */
export const UnitMappingRow = function ({
  mapping,
  existingPropertyMappings,
  paginatedUnitCodeMaps,
  entrataUnitCodes,
  loadingUnitCodes,
  updatePaginatedUnitCodeMaps,
}: UnitMappingRowParams) {
  const {
    yardiPropertyCode, yardiUnitCode, entrataUnitData, entrataPropertyCode,
  } = mapping;

  const alert = useAlert();
  /**
   * Generate unit code selection options for this particular row.
   */
  const options = useMemo(() => getUnitCodeOptions(
    paginatedUnitCodeMaps,
    entrataPropertyCode,
    entrataUnitCodes,
    existingPropertyMappings,
    alert,
  ), [paginatedUnitCodeMaps, entrataPropertyCode, entrataUnitCodes, existingPropertyMappings]);

  /**
     * Updates user selected Entrata unit within mapping.
     */
  const updateEntrataUnitCode = (unitMapping: UnitData, value: EntrataUnitData | null) => {
    const unitCodesRef = [...paginatedUnitCodeMaps];
    const relevantMapping = unitCodesRef.find(
      (unitMapRef) => unitMapRef.yardiPropertyCode === unitMapping.yardiPropertyCode
                && unitMapRef.yardiUnitCode === unitMapping.yardiUnitCode,
    );
    if (!relevantMapping) throw alertError(alert, 'Cannot update unit mapping');
    const emptyUnitData: EntrataUnitData = { unitId: '', unitNumber: '', buildingName: '' };
    relevantMapping.entrataUnitData = value || emptyUnitData;
    updatePaginatedUnitCodeMaps(unitCodesRef);
  };

  return (
    <div className="flex flex-row items-center my-4">
      <div className="w-1/4">{yardiPropertyCode}</div>
      <div className="w-1/4">{yardiUnitCode}</div>
      {/* Select Entrata unit code */}
      <Autocomplete
        options={options}
        loading={loadingUnitCodes}
        loadingText="Loading..."
        value={entrataUnitData}
        onChange={(e, value) => updateEntrataUnitCode(mapping, value)}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Entrata Unit Code" />}
        // if we don't make the rendering conditional, it will display empty parentheses prior to making a selection
        getOptionLabel={(unit) => `${unit.unitNumber} ${unit.buildingName ? `(${unit.buildingName})` : ''}`}
      />
    </div>
  );
};
