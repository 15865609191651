import React, { useEffect, useMemo, useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { PageType } from '../../api/navigation-api/NavigationTypes';
import { ApplicationContainer } from '../../helper';
import { getVisibleRunStatuses, watchUploadStatuses } from '../../api/statuses-page-api/StatusesPageAPI';
import { UploadStatus } from './statuses-page-types';
import { DataSource } from '../landing-page/landing-page-types';
import { StatusCard } from './StatusCard';

/**
 * Page that displays statuses of invoice uploads.
 */
export const StatusesPage = function () {
  // all uploadRun statuses in Firestore
  const [runStatuses, setRunStatuses] = useState<UploadStatus[]>([]);
  // data source to filter run statuses by
  const [selectedDataSource, setSelectedDataSource] = useState<DataSource>(DataSource.DIVVY);

  const updateRunStatuses = (statuses: UploadStatus[]) => setRunStatuses(statuses);

  // gets run statuses to display by filtering run statuses by dataSource
  const visibleRunStatuses = useMemo(
    () => (getVisibleRunStatuses(selectedDataSource, runStatuses)),
    [selectedDataSource, runStatuses],
  );

  /**
   * Watcher that pulls upload statuses in real-time.
   * */
  useEffect(() => {
    const unsubscribe = watchUploadStatuses(updateRunStatuses);
    return () => unsubscribe();
  }, []);

  // text to display if there are not yet any visible runs
  const noRunsText = `No ${selectedDataSource} invoices have been uploaded yet.`;

  return (
    <ApplicationContainer focusedPage={PageType.Statuses} className="bg-gray-50 h-[100vh] w-[100vw]">
      <div className="ml-44 font-inter flex flex-col">
        <div className="text-5xl font-semibold mt-10 mb-20">Upload Statuses</div>
        {/* Data source selector */}
        <ToggleButtonGroup
          value={selectedDataSource}
          exclusive
          onChange={(e, value) => {
            if (!value) return;
            setSelectedDataSource(value as DataSource);
          }}
          aria-label="text alignment"
        >
          <ToggleButton value={DataSource.DIVVY} aria-label="left aligned">
            Divvy
          </ToggleButton>
          <ToggleButton value={DataSource.RAMP} aria-label="centered">
            Ramp
          </ToggleButton>
        </ToggleButtonGroup>
        {/* Run statuses */}
        <div className="h-[70vh] overflow-y-scroll">
          {!visibleRunStatuses.length && (
          <div className="mt-10 text-2xl text-gray-500 text-center w-full">{noRunsText}</div>
          )}
          {visibleRunStatuses.map((statusData) => (<StatusCard statusData={statusData} />))}
        </div>
      </div>
    </ApplicationContainer>
  );
};
