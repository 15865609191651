import React, { useMemo } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { getPropertyCodeOptions } from '../../../api/landing-page-api/LandingPageAPI';
import { PropertyMappingRowParams } from '../landing-page-params';
import { EntrataProperty, PropertyCodeData } from '../landing-page-types';
import { useAlert } from '../../../api/alert-api/AlertAPI';
import { alertError } from '../../../api/error-api/ErrorAPI';

/**
 * Row used to map a Yardi property code to an Entrata property.
 * @param mapping property code mapping
 * @param unusedEntrataPropCodesInThisBatch list of unused entrata properties in the current paginated batch
 * @param existingPropertyMappings property mappings pulled from firestore
 * @param loadingPropertyCodes whether property code options are loading
 * @param paginatedPropCodeMaps paginated batch of property code maps
 * @param updatedPaginatedPropCodeMaps helper function to setPaginatedPropCodeMaps
 * @param entrataPropertyCodes list of all entrata property codes
 */
export const PropertyMappingRow = function ({
  mapping,
  unusedEntrataPropCodesInThisBatch,
  existingPropertyMappings,
  loadingPropertyCodes,
  paginatedPropCodeMaps,
  updatedPaginatedPropCodeMaps,
  entrataPropertyCodes,
}: PropertyMappingRowParams) {
  const { yardiPropertyCode, entrataPropertyCode } = mapping;

  // gets the currently selected entrata property from the currently selected entrata property ID
  // todo maybe change this - retrieving the object every time maybe not best practice?
  const currentlySelectedProperty = useMemo(() => entrataPropertyCodes.find(
    (property) => property.propertyId === entrataPropertyCode,
  ), [entrataPropertyCode, entrataPropertyCodes]);

  /**
   * Calculates property code options to display for this particular row.
   */
  const entrataPropertyCodeOptions = useMemo(() => getPropertyCodeOptions(
    currentlySelectedProperty,
    unusedEntrataPropCodesInThisBatch,
    existingPropertyMappings,
  ), [entrataPropertyCode, unusedEntrataPropCodesInThisBatch, existingPropertyMappings]);

  const alert = useAlert();
  /**
     * Updates user selected Entrata property within mapping.
     */
  const updateEntrataPropertyCode = (propMapping: PropertyCodeData, value: EntrataProperty | null) => {
    const mappingsRef = [...paginatedPropCodeMaps];
    const relevantMapping = mappingsRef.find(
      (mapRef) => mapRef.yardiPropertyCode === propMapping.yardiPropertyCode,
    );
    if (!relevantMapping) throw alertError(alert, 'Could not map property code');
    relevantMapping.entrataPropertyCode = value?.propertyId || '';
    updatedPaginatedPropCodeMaps(mappingsRef);
  };

  return (
    <div className="flex flex-row items-center my-4">
      <div className="w-1/4">{yardiPropertyCode}</div>
      {/* Select Entrata property code */}
      <Autocomplete
        options={entrataPropertyCodeOptions}
        loading={loadingPropertyCodes}
        loadingText="Loading..."
        value={currentlySelectedProperty}
        getOptionLabel={(option) => option.propertyName}
        onChange={(e, value) => updateEntrataPropertyCode(mapping, value)}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Entrata Property Code" />}
      />
    </div>
  );
};
