import React, { useEffect, useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { AxiosResponse } from 'axios';
import { RefreshOutlined } from '@mui/icons-material';
import { MappingParams } from '../landing-page-params';
import {
  EntrataUnitCodeMapping,
  MappingType,
  PropertyCodeData,
  UnitData,
  VendorData,
  VendorMatch,
} from '../landing-page-types';
import {
  addPropCodeMappingsToFirestore,
  addUnitCodeMappingsToFirestore,
  addVendorCodeMappingsToFirestore,
  getUnusedPropCodes, watchEntrataVendors,
} from '../../../api/landing-page-api/LandingPageAPI';
import { alertError } from '../../../api/error-api/ErrorAPI';
import { useAlert } from '../../../api/alert-api/AlertAPI';
import { getEntrataUnits, refreshEntrataVendors } from '../../../api/endpoints-api/EndpointsAPI';
import { UnitMappingRow } from './UnitMappingRow';
import { PropertyMappingRow } from './PropertyMappingRow';
import { VendorMappingRow } from './VendorMappingRow';

// We paginate within each batch of required mappings.
const paginationSize = 10;
/**
 * Mapping component is where the user will map any required data, e.g. yardi property code -> entrata property ID,
 * vendor name -> Entrata vendor, etc.
 * @param requiredPropCodeMaps all property code mappings required to be completed by user
 * @param refreshCodeMapping function to refresh mappings by updating data from Firestore
 * @param requiredUnitCodeMaps all unit code mappings required to be completed by user
 * @param existingPropertyMappings existing property mappings from Firestore (includes units)
 * @param existingVendorMappings existing vendor mappings from Firestore
 * @param requiredVendorCodeMaps all vendor code mappings required to be completed by user
 * @param entrataPropertyCodes list of all entrata properties
 * @param loadingPropertyCodes whether property codes are still loading from Entrata
 */
export const Mapping = function ({
  requiredPropCodeMaps,
  refreshCodeMapping,
  requiredUnitCodeMaps,
  existingPropertyMappings,
  requiredVendorCodeMaps,
  entrataPropertyCodes,
  loadingPropertyCodes,
}: MappingParams) {
  // used to store property code selections mapped to their related yardi property code. paginated sublist of total.
  const [paginatedPropCodeMaps, setPaginatedPropCodeMaps] = useState<PropertyCodeData[]>([]);
  // used to store unit code selections mapped to their related yardi unit code. paginated sublist of total.
  const [paginatedUnitCodeMaps, setPaginatedUnitCodeMaps] = useState<UnitData[]>([]);
  // used to store vendor name selections mapped to their related entrata vendor codes. paginated sublist of total.
  const [paginatedVendorCodeMaps, setPaginatedVendorCodeMaps] = useState<VendorMatch[]>([]);
  // list of entrata unit code options to select from when mapping.  only contains unit codes related to properties in current paginatedUnitCodeMaps.
  const [entrataUnitCodes, setEntrataUnitCodes] = useState<EntrataUnitCodeMapping | null>(null);
  // list of entrata vendor options to select from when mapping
  const [entrataVendors, setEntrataVendors] = useState<VendorData[]>([]);
  // what is currently being mapped - e.g. vendor code, unit code, etc. We always start with vendor
  const [mappingType, setMappingType] = useState<MappingType>(MappingType.VENDOR);
  // loading states
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [loadingUnitCodes, setLoadingUnitCodes] = useState<boolean>(false);

  const updatePaginatedVendorCodeMaps = (maps: VendorMatch[]) => setPaginatedVendorCodeMaps(maps);
  const updatePaginatedUnitCodeMaps = (maps: UnitData[]) => setPaginatedUnitCodeMaps(maps);
  const updatedPaginatedPropCodeMaps = (maps: PropertyCodeData[]) => setPaginatedPropCodeMaps(maps);
  const updateVendors = (vendors: VendorData[]) => setEntrataVendors(vendors);

  const alert = useAlert();

  /**
   * Sets MappingType dependent on remaining mappings required.
   */
  useEffect(() => {
    // Mapping order goes Vendor => Property => Unit
    if (paginatedVendorCodeMaps.length > 0) setMappingType(MappingType.VENDOR);
    else if (paginatedPropCodeMaps.length > 0) setMappingType(MappingType.PROPERTY);
    else setMappingType(MappingType.UNIT);
  }, [paginatedVendorCodeMaps, paginatedPropCodeMaps]);

  /**
   * Watcher that pulls Entrata vendors in real-time.
   * */
  useEffect(() => {
    const unsubscribe = watchEntrataVendors(updateVendors);
    return () => unsubscribe();
  }, []);

  /**
   * Retrieves Entrata property units.  This is dependent on properties since we only pull units for given properties.
   * We only want this to be re-pulled when we are finished mapping the prop codes and ready to start mapping unit codes
   */
  useEffect(() => {
    if (mappingType !== MappingType.UNIT) return;
    const propertyCodes = requiredUnitCodeMaps.map(({ entrataPropertyCode }) => entrataPropertyCode);
    const uniquePropertyCodes = Array.from(new Set(propertyCodes));
    if (!uniquePropertyCodes.length) return;
    setLoadingUnitCodes(true);
    getEntrataUnits(uniquePropertyCodes)
      .then(({ data }: AxiosResponse<EntrataUnitCodeMapping>) => setEntrataUnitCodes(data))
      .catch((err) => { throw alertError(alert, 'Failed to retrieve Entrata units', err); })
      .finally(() => setLoadingUnitCodes(false));
  }, [mappingType]);

  /**
   * These generate paginated code maps from required code maps whenever required code maps are updated.
   */
  useEffect(() => {
    setPaginatedPropCodeMaps([...requiredPropCodeMaps.slice(0, paginationSize)]);
  }, [requiredPropCodeMaps]);

  useEffect(() => {
    setPaginatedUnitCodeMaps([...requiredUnitCodeMaps.slice(0, paginationSize)]);
  }, [requiredUnitCodeMaps]);

  useEffect(() => {
    setPaginatedVendorCodeMaps([...requiredVendorCodeMaps.slice(0, paginationSize)]);
  }, [requiredVendorCodeMaps]);

  const unusedEntrataPropCodesInThisBatch = getUnusedPropCodes(paginatedPropCodeMaps, entrataPropertyCodes);

  const mappingTypeData = {
    [MappingType.UNIT]: {
      submit: () => addUnitCodeMappingsToFirestore(paginatedUnitCodeMaps, alert),
      missingValues: paginatedUnitCodeMaps.some(({ entrataUnitData }) => !entrataUnitData.unitNumber),
      required: requiredUnitCodeMaps,
    },
    [MappingType.VENDOR]: {
      submit: () => addVendorCodeMappingsToFirestore(paginatedVendorCodeMaps),
      missingValues: paginatedVendorCodeMaps.some(({ entrataVendor }) => !entrataVendor),
      required: requiredVendorCodeMaps,
    },
    [MappingType.PROPERTY]: {
      submit: () => addPropCodeMappingsToFirestore(paginatedPropCodeMaps),
      missingValues: paginatedPropCodeMaps.some(({ entrataPropertyCode }) => !entrataPropertyCode),
      required: requiredPropCodeMaps,
    },
  };

  // total number of mappings required for the given category
  const totalNewMappingsRequired = mappingTypeData[mappingType].required.length;

  /**
   *   Text that tracks mapping completion.  We do mathMin here in case the pagination size is less than the total
   *   mappings required i.e. 9 mappings required and the pagination size is 10.
   */
  const trackerText = `${Math.min(paginationSize, totalNewMappingsRequired)} 
  of ${totalNewMappingsRequired} ${mappingType.toLowerCase()} code mappings required`;

  // we want to disable submit if any row has not been mapped
  const disableSubmit = mappingTypeData[mappingType].missingValues;

  /**
   * Writes all user-made mappings in this batch to Firestore.
   */
  const submitFunction = () => {
    setLoadingSubmit(true);
    mappingTypeData[mappingType].submit()
      .then(() => refreshCodeMapping())
      .catch((err) => { throw alertError(alert, 'Failed to update codes', err); })
      .finally(() => setLoadingSubmit(false));
  };

  /**
   * Updates vendors by re-pulling from Entrata and writing to Firestore.
   */
  const refreshVendors = () => {
    refreshEntrataVendors()
      .then(() => {
        alert?.alert('Started updating vendors. Please allow 30 seconds for them to appear.', 'success');
      })
      .catch((err) => { throw alertError(alert, 'Failed to update vendors', err); });
  };

  const mappingUnitCodes = mappingType === MappingType.UNIT;
  const mappingVendors = mappingType === MappingType.VENDOR;
  const mappingPropertyCodes = mappingType === MappingType.PROPERTY;

  const dataHeader = mappingVendors ? 'Vendor Name' : 'Yardi Property Code';
  const selectHeader = `Entrata ${mappingType} Code`;

  return (
    <>
      <div className="overflow-y-scroll h-96">
        {/* Column Headers */}
        <div className="flex flex-row items-center my-4 font-semibold">
          <div className="w-1/4">{dataHeader}</div>
          {mappingUnitCodes && (<div className="w-1/4">Yardi Unit Code</div>)}
          <div className="w-1/4">{selectHeader}</div>
        </div>
        {/* First, map any vendor names to vendor codes */}
        {(paginatedVendorCodeMaps.map((mapping) => (
          <VendorMappingRow
            key={mapping.csvVendorName}
            mapping={mapping}
            entrataVendors={entrataVendors}
            paginatedVendorCodeMaps={paginatedVendorCodeMaps}
            updatePaginatedVendorCodeMaps={updatePaginatedVendorCodeMaps}
          />
        )))}
        {/* Next, map Yardi property codes to their Entrata counterparts */}
        {mappingPropertyCodes && (paginatedPropCodeMaps.map((mapping) => (
          <PropertyMappingRow
            key={mapping.yardiPropertyCode}
            mapping={mapping}
            existingPropertyMappings={existingPropertyMappings}
            unusedEntrataPropCodesInThisBatch={unusedEntrataPropCodesInThisBatch}
            loadingPropertyCodes={loadingPropertyCodes}
            paginatedPropCodeMaps={paginatedPropCodeMaps}
            updatedPaginatedPropCodeMaps={updatedPaginatedPropCodeMaps}
            entrataPropertyCodes={entrataPropertyCodes}
          />
        )))}
        {/* Once prop codes are all mapped, show unit codes which require mapping */}
        {(mappingUnitCodes && paginatedUnitCodeMaps.map((mapping) => (
          <UnitMappingRow
            key={mapping.yardiUnitCode}
            mapping={mapping}
            entrataUnitCodes={entrataUnitCodes}
            existingPropertyMappings={existingPropertyMappings}
            updatePaginatedUnitCodeMaps={updatePaginatedUnitCodeMaps}
            paginatedUnitCodeMaps={paginatedUnitCodeMaps}
            loadingUnitCodes={loadingUnitCodes}
          />
        )))}
      </div>
      <div className="flex flex-row justify-between mt-8">
        {/* Tracker text shows how many more mappings in this category are required */}
        <div className="text-right text-gray-500">{trackerText}</div>
        {/* Button to trigger updateVendors function, which will re-pull vendors from Entrata to Firestore */}
        {mappingVendors && (
        <Button
          startIcon={<RefreshOutlined />}
          onClick={refreshVendors}
          variant="outlined"
        >
          Refresh Vendors
        </Button>
        )}
        {/* Submit paginated selections */}
        <Button
          className="bg-blue-500 hover:bg-blue-400 text-white disabled:bg-blue-400 disabled:text-white w-28 h-10"
          disabled={disableSubmit}
          onClick={submitFunction}
        >
          {loadingSubmit ? <CircularProgress size={30} className="text-white" /> : 'Submit'}
        </Button>
      </div>
    </>
  );
};
