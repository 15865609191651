import {
  Button, Card, CircularProgress, Dialog, ToggleButton, ToggleButtonGroup,
} from '@mui/material';
import {
  FolderZip, PlayCircleFilled, RefreshOutlined, Upload, WarningAmber,
} from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { DataSource, MostRecentFiles } from './landing-page-types';
import { getDivvyFiles, getRampFiles } from '../../api/endpoints-api/EndpointsAPI';
import { ImportsParams } from './landing-page-params';
import { alertError } from '../../api/error-api/ErrorAPI';
import { useAlert } from '../../api/alert-api/AlertAPI';
import { driveFolderIds, getDriveUrl } from '../../api/landing-page-api/LandingPageAPI';

/**
 * Displays the file names of the files from GDrive that will be used to generate the invoices for upload.
 * This is the first main component users will see within LandingPage.
 * @param selectedFiles divvy or ramp files pulled from GDrive
 * @param updateDivvyFiles helper function to setDivvyFiles
 * @param updateRampFiles helper function to setRampFiles
 * @param updateStartedProcess helper function to setStartedProcess
 * @param selectedDataSource the selected data source - e.g. divvy or ramp
 * @param updateSelectedDataSource helper function to setSelectedDataSource
 */
export const Imports = function ({
  selectedFiles,
  updateDivvyFiles,
  updateRampFiles,
  updateStartedProcess,
  selectedDataSource,
  updateSelectedDataSource,
}: ImportsParams) {
  const [filesLoading, setFilesLoading] = useState<boolean>(true);
  // shows warning re: not running uploader at the same time as another person
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const alert = useAlert();
  // Google Drive folder IDs where user should upload files
  const { imagesFolderId, importsFolderId } = driveFolderIds[selectedDataSource];

  /**
     * Retrieves Divvy or Ramp files from GDrive, depending on selected data source.
     */
  const retrieveFiles = () => {
    setFilesLoading(true);

    const dataFuncs = selectedDataSource === DataSource.DIVVY
      ? { update: updateDivvyFiles, retrieve: getDivvyFiles }
      : { update: updateRampFiles, retrieve: getRampFiles };

    dataFuncs.retrieve()
      .then(({ data }: AxiosResponse<MostRecentFiles | null>) => dataFuncs.update(data))
      .catch((err: AxiosError<string>) => {
        const errorMessage = err.response?.data || 'unhandled error';
        throw alertError(alert, `Failed to pull ${selectedDataSource} files: ${errorMessage}`, err, 10000);
      })
      .finally(() => setFilesLoading(false));
  };

  /**
     * Whenever user toggles dataSource, will re-pull relevant data files.
     */
  useEffect(() => {
    retrieveFiles();
  }, [selectedDataSource]);

  // simultaneous run warning text
  const warningText = `Before you begin, make sure no one else is currently in the process of uploading
   ${selectedDataSource} invoices. `;
  const warningTextBold = `Simultaneous uploading of ${selectedDataSource} invoices can result in errors!`;

  // text to display when there are no Drive files
  const noDriveFilesText = `Please ensure that you have uploaded an Imports CSV 
  ${selectedDataSource === DataSource.DIVVY ? 'AND an Images Zip file' : ''}
   and refresh the page.`;

  return (
    <>
      {/* Simultaneous run warning */}
      <Dialog onClose={() => setShowWarning(false)} open={showWarning}>
        <div className="p-8 flex flex-col items-center">
          <div className="text-2xl font-semibold mb-4">
            <WarningAmber className="mr-4 text-yellow-500" />
            Simultaneous Run Warning
          </div>
          <div>
            {warningText}
            <span className="font-medium">{warningTextBold}</span>
          </div>
          <Button
            className="bg-blue-500 hover:bg-blue-400 text-white w-28 my-4"
            onClick={() => {
              setShowWarning(false);
              updateStartedProcess(true);
            }}
          >
            Continue
          </Button>
        </div>
      </Dialog>
      {/* Main content */}
      <div className="flex flex-row justify-between">
        {/*  Data source selector */}
        <ToggleButtonGroup
          value={selectedDataSource}
          exclusive
          onChange={(e, value) => {
            if (!value) return;
            updateSelectedDataSource(value as DataSource);
          }}
          aria-label="text alignment"
        >
          <ToggleButton value={DataSource.DIVVY} aria-label="left aligned">
            Divvy
          </ToggleButton>
          <ToggleButton value={DataSource.RAMP} aria-label="centered">
            Ramp
          </ToggleButton>
        </ToggleButtonGroup>
        {/* Upload buttons that link to Drive folders */}
        <div className="flex flex-row">
          <Button
            className="w-56 h-12 rounded-xl mr-4"
            startIcon={<Upload />}
            variant="outlined"
            onClick={() => { window.open(getDriveUrl(importsFolderId)); }}
          >
            Upload Imports CSV
          </Button>
          {/* Only displays this button if there is an images Zip requirement (which only applies to Divvy) */}
          {imagesFolderId && (
          <Button
            className="w-56 h-12 rounded-xl mr-4"
            startIcon={<FolderZip />}
            variant="outlined"
            onClick={() => { window.open(getDriveUrl(imagesFolderId)); }}
          >
            Upload Images Zip
          </Button>
          )}
        </div>
        {/* Refresh button - re-pulls files */}
        <Button
          className="bg-blue-500 text-white hover:bg-blue-400 rounded-xl"
          startIcon={<RefreshOutlined />}
          onClick={retrieveFiles}
        >
          Refresh
        </Button>
      </div>
      {/* File name cards */}
      <div className="flex flex-row justify-around mt-20">
        {!filesLoading && selectedFiles && (
        <>
          <Card variant="outlined" className="drop-shadow-2xl w-96 p-4">
            <div className="text-2xl font-medium">Import CSV</div>
            <div>{selectedFiles.importsFile.name}</div>
          </Card>
          {/* Only Divvy will have an images Zip file name - with Ramp, we will be pulling the invoices from the Ramp API */}
          {selectedFiles.imagesZipFileName
              && (
              <Card variant="outlined" className="drop-shadow-2xl w-96 p-4">
                <div className="text-2xl font-medium">Images Zip</div>
                <div>{selectedFiles.imagesZipFileName}</div>
              </Card>
              )}
        </>
        )}
        {/* Placeholder text shown if no files are able to be retrieved */}
        {!filesLoading && !selectedFiles && (
        <div className="flex flex-col text-gray-500 text-2xl text-center">
          <div>File(s) unable to be retrieved from Google Drive.</div>
          <div className="mt-4">{noDriveFilesText}</div>
        </div>
        )}
        {/* Loading icon for files */}
        {filesLoading && (<CircularProgress />)}
      </div>
      <div className="flex flex-row justify-center mt-32">
        {/* Start button */}
        <Button
          className="bg-blue-500 text-white hover:bg-blue-400 w-44 h-12 rounded-2xl
              disabled:text-white disabled:bg-blue-400"
          startIcon={<PlayCircleFilled />}
          disabled={!selectedFiles}
          onClick={() => setShowWarning(true)}
        >
          Start
        </Button>
      </div>
    </>
  );
};
