import axios from 'axios';
import { DataSource } from '../../pages/landing-page/landing-page-types';

const localBackend = 'http://127.0.0.1:5001/wreg-entrata-divvy-ramp-patch/us-central1/entrataDivvyApp';
/**
 * If there is no env variable specified (which is how it's run locally), then use the local backend url.
 */
const backendUrl = process.env.REACT_APP_BACKEND_URL || localBackend;

const entrataEndpoints = `${backendUrl}/entrata`;
const driveEndpoints = `${backendUrl}/drive`;

/**
 * Get Divvy invoices files from Drive (imports file and images zip).
 */
export const getDivvyFiles = async () => axios.get(`${driveEndpoints}/getDivvyFiles`);

/**
 * Get Ramp invoices file from Drive (just imports file).
 */
export const getRampFiles = async () => axios.get(`${driveEndpoints}/getRampFiles`);

/**
 * Get all Entrata properties.
 */
export const getEntrataProperties = async () => axios.get(`${entrataEndpoints}/getProperties`);

/**
 * Get all Entrata property units given a list of Entrata property Ids.
 * @param propertyIds properties we want to get units from
 */
export const getEntrataUnits = async (propertyIds: string[]) => {
  const params = { propertyIds };
  return axios.get(`${entrataEndpoints}/getUnits`, { params });
};

/**
 * Trigger the start of the uploadInvoices process.
 * @param dataSource divvy/ramp
 */
export const uploadInvoicesToEntrata = async (
  dataSource: DataSource,
) => axios.post(`${entrataEndpoints}/uploadInvoices`, { dataSource });

/**
 * Triggers pub/sub from backend which triggers function that will re-pull vendors from Entrata to Firestore.
 */
export const refreshEntrataVendors = async () => axios.get(`${entrataEndpoints}/updateVendors`);
