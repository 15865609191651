import { Dialog, IconButton } from '@mui/material';
import { TrashIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { VendorMapping } from '../../landing-page/landing-page-types';
import { removeMatchFromVendor } from '../../../api/settings-api/SettingsAPI';
import { alertError } from '../../../api/error-api/ErrorAPI';
import { useAlert } from '../../../api/alert-api/AlertAPI';
import { VendorId } from '../settings-page-types';

/**
 * Modal which allows the user to delete vendor name matches from a vendor mapping object.
 * @param focusedVendor the vendor being edited
 * @param updateFocusedVendor sets focused vendor (the vendor populating this modal)
 */
export const DeleteVendorNamesModal = function ({ focusedVendor, updateFocusedVendor }:{
  focusedVendor: VendorMapping, updateFocusedVendor: (vendor: VendorId | null) => void
}) {
  const alert = useAlert();
  /**
     * Removes csv vendor name from list of matches for a given Entrata vendor.
     * @param vendorName the vendor name we are removing from the list of matches
     * @param vendor the vendor we are updating
     */
  const removeMatch = (vendorName: string, vendor: VendorMapping) => {
    removeMatchFromVendor(vendorName, vendor)
      .then(() => alert?.alert('Deleted vendor name from vendor matches', 'success'))
      .catch((err) => { throw alertError(alert, 'Failed to remove match from vendor', err); });
  };
  return (
    <Dialog onClose={() => updateFocusedVendor(null)} open={!!focusedVendor}>
      <div className="w-[30rem] h-96 font-inter p-8">
        <div className="font-semibold text-center text-xl">Delete Vendor Matches</div>
        <div className="text-center text-sm">
          Deleting an item below will remove its association with the vendor.
        </div>
        <div className="my-4">
          {focusedVendor.csvVendorName.map((vendor) => (
            <div className="h-14 w-full bg-gray-100 my-4 rounded-md flex flex-col justify-center">
              <div className="flex flex-row justify-between items-center">
                <div className="ml-2">{vendor}</div>
                <IconButton onClick={() => removeMatch(vendor, focusedVendor)} className="mr-2">
                  <TrashIcon className="h-6 text-red-500" />
                </IconButton>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Dialog>
  );
};
