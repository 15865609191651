import React from 'react';
import { Button } from '@mui/material';
import { signIn, tr } from '../../api';
import BackgroundSVG from '../../../assets/westland-logos/LoginPageBackgroundLogo.svg';

/**
 *
 * The elements responsible for displaying and rendering the login screen.
 * Functionality handled by the LogInOutAPI
 * @constructor
 */
export const LoginPage = function () {
  return (
    <div className="h-screen overflow-hidden">
      <div
        className="flex justify-center flex-col items-center"
      >
        <img
          className="object-cover"
          src={BackgroundSVG}
          alt="Background Westland Logo"
        />
        <div className="flex flex-col items-center justify-center basis-1/2
            absolute bottom-0 right-0 mr-[5vw] mb-[5vh]"
        >
          <div className="absolute h-full w-full " />
          <span
            className="tracking-wide
            text-3xl 2xl:text-6xl xl:text-5xl md:text-4xl sm:text-3xl
            font-poppins text-black font-medium xl:mb-24 md:mb-16"
          >
            Welcome to the Entrata Divvy/Ramp Handler!
          </span>
          <Button
            onClick={signIn}
            className="font-poppins
            h-auto w-auto text-lg
            2xl:text-5xl
            xl:text-4xl
            md:text-3xl
            md:text-lg
            p-5
            "
            style={{ backgroundColor: '#21262D', textTransform: 'none' }}
            size="large"
            variant="contained"
          >
            {tr('login_button')}
          </Button>
          <div className="mb-20" />
        </div>
      </div>
    </div>
  );
};
