import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import { Button, Chip } from '@mui/material';
import { Download } from '@mui/icons-material';
import { UploadStatus } from './statuses-page-types';
import {
  downloadStatusesXlsx, getRunAmountTotal,
  getStatusBreakdown,
} from '../../api/statuses-page-api/StatusesPageAPI';

/**
 * Displays status information for a given uploadRun.
 * @param statusData uploadRun status data
 */
export const StatusCard = function ({ statusData }:{ statusData: UploadStatus }) {
  const [runId, runData] = statusData;
  const { runStartTimestamp, statuses, fileNames } = runData;
  const { imports, imagesZip } = fileNames;

  /**
     * Calculate breakdown of how many uploads failed, succeeded, etc.
     */
  const { uploadsSuccessful, uploadsPending, uploadsFailed } = useMemo(
    () => getStatusBreakdown(statuses),
    [statuses],
  );

  // gets sum of all amounts in an upload run
  const totalAmount = getRunAmountTotal(runData);
  const uploadsSumText = `Attempted uploads total: $${totalAmount}`;

  const readableRunStartTimestamp = dayjs.unix(runStartTimestamp).format('MM/DD/YYYY hh:mm:ssa');
  const jobStartedText = `Job started at ${readableRunStartTimestamp}`;

  return (
    <div className="bg-white w-4/5 drop-shadow-2xl rounded-2xl my-4 py-6 px-10" key={runId}>
      {/* What time the job started */}
      <div className="mb-4">{jobStartedText}</div>
      {/* Filenames of imports and (if Divvy) images Zip file */}
      <div className="flex flex-row">
        <div className="w-1/2">
          Imports file: &nbsp;
          <span className="font-semibold">{imports}</span>
        </div>
        {imagesZip && (
        <div>
          Images Zip file: &nbsp;
          <span className="font-semibold">{imagesZip}</span>
        </div>
        )}
      </div>
      {/* Chips that display how many jobs remain at each status */}
      <div className="flex flex-row justify-between my-4">
        <div>Uploads statuses:</div>
        <div className="flex flex-row justify-evenly w-3/5">
          <Chip label={`Pending: ${uploadsPending}`} className="bg-yellow-200 text-yellow-800" />
          <Chip label={`Successful: ${uploadsSuccessful}`} className="bg-green-200 text-green-800" />
          <Chip label={`Failed: ${uploadsFailed}`} className="bg-red-200 text-red-800" />
        </div>
      </div>
      {/* Download statuses CSV button */}
      <div className="flex flex-row justify-between">
        <Button
          className="bg-blue-500 hover:bg-blue-400 text-white"
          onClick={() => downloadStatusesXlsx(statusData)}
          startIcon={<Download />}
        >
          Download statuses
        </Button>
        {/*  Total of all attempted upload amounts */}
        <div className="text-lg font-medium">{uploadsSumText}</div>
      </div>
    </div>
  );
};
