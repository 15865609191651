import React, { useEffect, useState } from 'react';
import { getDoc } from 'firebase/firestore';
import { Button, CircularProgress } from '@mui/material';
import { AxiosResponse } from 'axios';
import { PageType } from '../../api/navigation-api/NavigationTypes';
import { ApplicationContainer } from '../../helper';
import {
  DataSource, EntrataProperty, HeadersDoc, MostRecentFiles, PropertyCodeData, UnitData, VendorMapping, VendorMatch,
} from './landing-page-types';
import { Imports } from './Imports';
import { alertError } from '../../api/error-api/ErrorAPI';
import { useAlert } from '../../api/alert-api/AlertAPI';
import {
  getRequiredPropCodeMaps,
  getRequiredDivvyUnitCodeMaps,
  getRequiredVendorMaps, firestoreDocRefs, autoMapProperties,
} from '../../api/landing-page-api/LandingPageAPI';
import { Mapping } from './mapping/Mapping';
import { getEntrataProperties, uploadInvoicesToEntrata } from '../../api/endpoints-api/EndpointsAPI';
import { ReviewVendorSettings } from './ReviewVendorSettings';

/**
 * The landing/home page of the application.  This is the main page that the user will interact with to map
 * information and start the invoice upload process.
 */
export const LandingPage = function () {
  // divvy files most recently uploaded to Google Drive
  const [divvyFiles, setDivvyFiles] = useState<MostRecentFiles | null>(null);
  // ramp files most recently uploaded to Google Drive
  const [rampFiles, setRampFiles] = useState<MostRecentFiles | null>(null);
  // existing property mappings that are stored in Firestore (mapping yardi -> entrata properties)
  const [existingPropertyMappings, setExistingPropertyMappings] = useState<PropertyCodeData[]>([]);
  // existing vendor mappings that are stored in Firestore (mapping vendor name -> entrata vendor)
  const [existingVendorMappings, setExistingVendorMappings] = useState<VendorMapping[]>([]);
  // required property mappings, pulled from imports CSV, that user must complete before starting invoice uploads
  const [requiredPropCodeMaps, setRequiredPropCodeMaps] = useState<PropertyCodeData[]>([]);
  // required unit mappings, pulled from imports CSV, that user must complete before starting invoice uploads
  const [requiredUnitCodeMaps, setRequiredUnitCodeMaps] = useState<UnitData[]>([]);
  // required vendor mappings, pulled from imports CSV, that user must complete before starting invoice uploads
  const [requiredVendorCodeMaps, setRequiredVendorCodeMaps] = useState<VendorMatch[]>([]);
  // whether the user has clicked the Start button to start the mapping, and eventually, upload process
  const [startedProcess, setStartedProcess] = useState<boolean>(false);
  // state used to trigger re-pulls of mapping data from Firestore
  const [refreshMapping, setRefreshMapping] = useState<boolean>(false);
  // selected invoice files source (Divvy or Ramp)
  const [selectedDataSource, setSelectedDataSource] = useState<DataSource>(DataSource.DIVVY);
  // loading state after clicking button to start invoice upload process
  const [loadingUpload, setLoadingUpload] = useState<boolean>(false);
  const [csvHeaders, setCsvHeaders] = useState<HeadersDoc | null>(null);
  // list of entrata property options to select from when mapping
  const [entrataPropertyCodes, setEntrataPropertyCodes] = useState<EntrataProperty[]>([]);
  const [loadingPropertyCodes, setLoadingPropertyCodes] = useState<boolean>(false);
  // whether user has reviewed vendor settings post-upload
  const [vendorSettingsReviewed, setVendorSettingsReviewed] = useState<boolean>(false);
  // selected data source can either be Divvy or Ramp
  const isDivvy = selectedDataSource === DataSource.DIVVY;
  // we choose the data related to the user-selected data source
  const selectedFiles = isDivvy ? divvyFiles : rampFiles;

  // helper functions to be drilled down
  const updateStartedProcess = (start: boolean) => setStartedProcess(start);
  const updateDivvyFiles = (files: MostRecentFiles | null) => setDivvyFiles(files);
  const updateRampFiles = (files: MostRecentFiles | null) => setRampFiles(files);
  const updateSelectedDataSource = (source: DataSource) => setSelectedDataSource(source);
  const refreshCodeMapping = () => setRefreshMapping(!refreshMapping);
  const updateVendorSettingsReviewed = (reviewed: boolean) => setVendorSettingsReviewed(reviewed);

  const alert = useAlert();

  /**
   * Retrieves CSV header mappings for Divvy/Ramp Imports CSVs as well as all Entrata properties.
   */
  useEffect(() => {
    getDoc(firestoreDocRefs.csvHeaders)
      .then((docSnap) => {
        if (docSnap.exists()) setCsvHeaders(docSnap.data() as HeadersDoc);
      })
      .catch((err) => { throw alertError(alert, 'Could not retrieve CSV header mappings', err); });
    setLoadingPropertyCodes(true);
    getEntrataProperties()
      .then(({ data }: AxiosResponse<EntrataProperty[]>) => setEntrataPropertyCodes(data))
      .catch((err) => { throw alertError(alert, 'Failed to pull Entrata property codes', err); })
      .finally(() => setLoadingPropertyCodes(false));
  }, []);

  /**
   * Pulls property and vendor mappings from Firestore whenever refresh state is changed.
   */
  useEffect(() => {
    getDoc(firestoreDocRefs.propertyMappings)
      .then((docSnap) => {
        if (docSnap.exists()) setExistingPropertyMappings(docSnap.data().mappings as PropertyCodeData[]);
      })
      .catch((err) => { throw alertError(alert, 'Could not retrieve existing property mappings', err); });
    getDoc(firestoreDocRefs.vendorMappings)
      .then((docSnap) => {
        if (docSnap.exists()) setExistingVendorMappings(docSnap.data().mappings as VendorMapping[]);
      })
      .catch((err) => { throw alertError(alert, 'Could not retrieve existing vendor mappings', err); });
  }, [refreshMapping]);

  /**
   * Generates required mappings whenever mappings are pulled from Firestore and/or files are pulled from Drive.
   */
  useEffect(() => {
    if (!selectedFiles || !csvHeaders) return;
    const { importsFile } = selectedFiles;
    // auto-map any properties possible to their entrata counterparts
    const newAutoMappings = autoMapProperties(
      importsFile,
      existingPropertyMappings,
      isDivvy,
      csvHeaders,
      entrataPropertyCodes,
    );
    // this represents the new existing property mappings (just hasnt been updated in firestore yet so we have to pass it ourselves)
    const updatedPropertyMappings = [...existingPropertyMappings, ...newAutoMappings];
    // add the mapping to the newMappingsRequired list if it doesn't already exist in existingMappings and has not just been auto-mapped
    const propertyCodeMappings = getRequiredPropCodeMaps(
      importsFile,
      updatedPropertyMappings,
      isDivvy,
      csvHeaders,
    );
    // ramp does not have unit codes, also all prop codes must be mapped before calculating unit maps since they are dependent
    const unitCodeMappings = isDivvy && propertyCodeMappings.length <= 0
      ? getRequiredDivvyUnitCodeMaps(importsFile, updatedPropertyMappings, csvHeaders.divvy, alert)
      : [];
    const vendorMaps = getRequiredVendorMaps(importsFile, existingVendorMappings, isDivvy, csvHeaders);
    setRequiredPropCodeMaps(propertyCodeMappings);
    setRequiredUnitCodeMaps(unitCodeMappings);
    setRequiredVendorCodeMaps(vendorMaps);
  }, [existingPropertyMappings, existingVendorMappings, selectedFiles]);

  const mappingsRequired = requiredPropCodeMaps.length > 0 || requiredUnitCodeMaps.length > 0
      || requiredVendorCodeMaps.length > 0;

  /**
   * Triggers the start of invoice upload process on the backend.
   */
  const uploadInvoices = () => {
    setLoadingUpload(true);
    uploadInvoicesToEntrata(selectedDataSource)
      .then(() => { alert?.alert('Started invoices upload', 'success'); })
      .catch((err) => { throw alertError(alert, 'Failed to start invoices upload', err); })
      .finally(() => setLoadingUpload(false));
  };

  // vendor settings (useOriginalInvoiceNumber) only must be reviewed if on Divvy
  const reviewVendorSettings = !vendorSettingsReviewed && isDivvy;
  // we can only proceed to upload with divvy if vendor settings have been reviewed
  const proceedToUpload = isDivvy ? vendorSettingsReviewed : !vendorSettingsReviewed;

  return (
    <ApplicationContainer focusedPage={PageType.Home} className="bg-gray-50 h-[100vh] w-[100vw]">
      <div className="ml-44 font-inter flex flex-col">
        <div className="text-5xl font-semibold mt-10 mb-20">Entrata Divvy/Ramp Handler</div>
        <div className="bg-white border-gray-200 border-[1px] h-[60vh] w-[85vw] rounded-xl p-8">
          {/* Show imports screen */}
          {!startedProcess && (
          <Imports
            selectedFiles={selectedFiles}
            selectedDataSource={selectedDataSource}
            updateSelectedDataSource={updateSelectedDataSource}
            updateRampFiles={updateRampFiles}
            updateDivvyFiles={updateDivvyFiles}
            updateStartedProcess={updateStartedProcess}
          />
          )}
          {/* Show mapping screen if mappings required and user has clicked start */}
          {startedProcess && mappingsRequired && (
          <Mapping
            requiredPropCodeMaps={requiredPropCodeMaps}
            requiredUnitCodeMaps={requiredUnitCodeMaps}
            requiredVendorCodeMaps={requiredVendorCodeMaps}
            refreshCodeMapping={refreshCodeMapping}
            existingPropertyMappings={existingPropertyMappings}
            entrataPropertyCodes={entrataPropertyCodes}
            loadingPropertyCodes={loadingPropertyCodes}
          />
          )}
          {/* Review vendor settings */}
          {startedProcess && !mappingsRequired && reviewVendorSettings && divvyFiles && csvHeaders && (
          <ReviewVendorSettings
            divvyFiles={divvyFiles}
            csvHeaders={csvHeaders}
            updateVendorSettingsReviewed={updateVendorSettingsReviewed}
          />
          )}
          {/* Upload invoices button */}
          {startedProcess && !mappingsRequired && proceedToUpload && (
          <div className="flex flex-row justify-center h-full items-center">
            <Button
              className="bg-blue-500 text-white hover:bg-blue-400 hover:text-white h-16 w-44 rounded-2xl"
              onClick={uploadInvoices}
            >
              {loadingUpload ? <CircularProgress className="text-white" /> : 'Upload Invoices'}
            </Button>
          </div>
          )}
        </div>
      </div>
    </ApplicationContainer>
  );
};
