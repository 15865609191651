import {
  doc, getDoc, onSnapshot, updateDoc,
} from 'firebase/firestore';
import {
  GoogleDriveFileData,
  PropertyCodeData,
  UnitData,
  EntrataUnitCodeMapping,
  CsvRow,
  VendorMapping,
  VendorData,
  FirestoreCollections,
  FirestoreDocs,
  HeadersDoc,
  DivvyHeaders, UnitCode, EntrataUnitData, EntrataProperty, VendorMatch, DataSource, VendorsDoc,
} from '../../pages/landing-page/landing-page-types';
import { db } from '../../../firebase-config';
import { alertError } from '../error-api/ErrorAPI';
import { AlertHelper } from '../alert-api/AlertAPI';

/**
 * Firestore doc refs of various docs in Firestore.
 */
export const firestoreDocRefs = {
  // mappings of property data to Entrata properties
  propertyMappings: doc(db, FirestoreCollections.CODE_MAPPINGS, FirestoreDocs.PROPERTY_MAPPINGS),
  // mappings of vendor data to Entrata vendors
  vendorMappings: doc(db, FirestoreCollections.CODE_MAPPINGS, FirestoreDocs.VENDOR_MAPPINGS),
  // store of Entrata vendor data
  vendorCodes: doc(db, FirestoreCollections.CODES, FirestoreDocs.VENDOR_CODES),
  // mapping of CSV headers for divvy/ramp imports CSVs
  csvHeaders: doc(db, FirestoreCollections.HEADERS, FirestoreDocs.IMPORTS_CSV),
};

/**
 * Gets Yardi property code from string where string is in format "[property code] [property name]"
 * @param codeString property code string to be parsed
 */
export const getPropertyCodeFromString = (codeString: string) => codeString.split(' ')[0];

/**
 * Retrieve all unique property codes from imports csv that have not been mapped in Firestore.
 * @param csvHeaders csv headers
 * @param isDivvy divvy or ramp
 * @param importsFile imports CSV
 * @param existingPropertyMappings properties already mapped (from firestore)
 */
export const getUnmappedPropCodesFromCsv = (
  csvHeaders: HeadersDoc,
  isDivvy: boolean,
  importsFile: GoogleDriveFileData<CsvRow[]>,
  existingPropertyMappings: PropertyCodeData[],
) => {
  const { divvy, ramp } = csvHeaders;
  const propertyCodeHeader = isDivvy ? divvy.YardiPropertyCode : ramp.YardiPropertyCode;
  const propertyCodes = importsFile.data.map((row) => getPropertyCodeFromString(row[propertyCodeHeader]));
  // remove duplicates
  const uniquePropertyCodes = Array.from(new Set(propertyCodes));
  // filter out all already mapped property codes
  const alreadyMappedPropCodes = existingPropertyMappings.map((mapping) => mapping.yardiPropertyCode);
  return uniquePropertyCodes.filter((code) => !alreadyMappedPropCodes.includes(code));
};

/**
 * Calculates the required property code maps for an invoice upload.  Looks at all Yardi property codes referenced
 * in Imports CSV and creates mapping objects for all property codes which are not already mapped in Firestore.
 * @param importsFile imports CSV containing data to be uploaded as invoices
 * @param existingPropertyMappings property codes that have already been mapped
 * @param isDivvy whether the data source is divvy (if not, it's ramp)
 * @param csvHeaders csv headers mapping doc
 */
export const getRequiredPropCodeMaps = (
  importsFile: GoogleDriveFileData<CsvRow[]>,
  existingPropertyMappings: PropertyCodeData[],
  isDivvy: boolean,
  csvHeaders: HeadersDoc,
) => {
  // get all previously unmapped property codes from the csv
  const unmappedPropertyCodes = getUnmappedPropCodesFromCsv(csvHeaders, isDivvy, importsFile, existingPropertyMappings);
  // generate a mapping for each valid code
  return unmappedPropertyCodes.map((yardiPropertyCode) => ({ yardiPropertyCode, entrataPropertyCode: '', units: [] }));
};

/**
 * Extracts unit data from the CSV.
 * @param divvyImportsFile imports CSV
 * @param divvyHeaders divvy csv headers
 */
export const getUnitsFromCsv = (
  divvyImportsFile: GoogleDriveFileData<CsvRow[]>,
  divvyHeaders: DivvyHeaders,
) => {
  const { YardiPropertyCode, UnitNumber } = divvyHeaders;
  return divvyImportsFile.data.reduce((acc, row) => {
    const propertyCode = getPropertyCodeFromString(row[YardiPropertyCode]);
    const unitCode = row[UnitNumber];
    // we don't want to extract any duplicate info i.e. where there is the same unit code/prop code combination
    const alreadyInAcc = acc.some(
      (mapping) => mapping.unitCode === unitCode && mapping.propertyCode === propertyCode,
    );
    // we don't want to extract this if there is no unit code or if the unit code is not valid
    const validUnitCode = !!unitCode && unitCode !== 'N/A';
    if (!alreadyInAcc && validUnitCode) acc.push({ propertyCode, unitCode });
    return acc;
  }, [] as UnitCode[]);
};

/**
 * Given a unit and existing property mappings, returns whether that unit already exists in existingPropertyMappings.
 * @param unit unit we are checking
 * @param existingPropertyMappings existing property and unit mappings
 */
export const unitCodeAlreadyMapped = (
  unit: UnitCode,
  existingPropertyMappings: PropertyCodeData[],
) => existingPropertyMappings.some(
  (existingMapping) => existingMapping.yardiPropertyCode === unit.propertyCode
          && existingMapping.units.some(({ yardiUnitCode }) => yardiUnitCode === unit.unitCode),
);

/**
 * Calculates the required unit code maps for a Divvy invoice upload (Ramp does not have unit codes).
 * Looks at all Yardi property & unit codes referenced in Imports CSV and creates mapping objects for all
 * unit codes which are not already mapped under their respective properties in Firestore.
 * @param divvyImportsFile imports CSV
 * @param existingPropertyMappings property codes and unit codes that have already been mapped
 * @param divvyHeaders divvy CSV headers mapping
 * @param alert alert context
 */
export const getRequiredDivvyUnitCodeMaps = (
  divvyImportsFile: GoogleDriveFileData<CsvRow[]>,
  existingPropertyMappings: PropertyCodeData[],
  divvyHeaders: DivvyHeaders,
  alert: AlertHelper | null,
) => {
  // extract property code and unit code from each row - filters for uniqueness
  const propertyUnits = getUnitsFromCsv(divvyImportsFile, divvyHeaders);
  // filter out all units that have already been mapped in Firestore
  const unmappedUnits = propertyUnits.filter((unit) => !unitCodeAlreadyMapped(unit, existingPropertyMappings));
  // generate unit mappings
  return unmappedUnits.map(({ propertyCode, unitCode }) => {
    // we will copy over the property mapping from the existing properties and insert a new unit mapping
    const propertyMapped = existingPropertyMappings.find(({ yardiPropertyCode }) => yardiPropertyCode === propertyCode);
    if (!propertyMapped) throw alertError(alert, 'Cannot map unit to unmapped property');
    return {
      yardiPropertyCode: propertyCode,
      yardiUnitCode: unitCode,
      entrataUnitData: { unitNumber: '', unitId: '', buildingName: '' },
      entrataPropertyCode: propertyMapped.entrataPropertyCode,
    };
  });
};

/**
 * Calculates the required vendor maps for an invoice upload.  Looks at all vendor names referenced
 * in Imports CSV and creates mapping objects for all vendor names which are not already mapped in Firestore.
 * @param importsFile imports CSV file
 * @param existingVendorMappings vendor names which have already been mapped
 * @param isDivvy whether the data source is divvy (if not, it's ramp)
 * @param csvHeaders csv headers mapping doc
 */
export const getRequiredVendorMaps = (
  importsFile: GoogleDriveFileData<CsvRow[]>,
  existingVendorMappings: VendorMapping[],
  isDivvy: boolean,
  csvHeaders: HeadersDoc,
): VendorMatch[] => {
  const { divvy, ramp } = csvHeaders;
  // extract values from different column depending on input data source
  const vendorNameHeader = isDivvy ? divvy.VendorName : ramp.VendorName;
  const vendorNames = importsFile.data.map((row) => row[vendorNameHeader]);
  // remove duplicates
  const uniqueVendorNames = Array.from(new Set(vendorNames));
  // filter out any vendor names which have already been mapped
  const alreadyMappedVendorNames = existingVendorMappings.map((mapping) => mapping.csvVendorName).flat();
  const unmappedVendorNames = uniqueVendorNames.filter((name) => !alreadyMappedVendorNames.includes(name));
  // generate mappings
  return unmappedVendorNames.map((name) => ({
    csvVendorName: name,
    entrataVendor: null,
    useOriginalInvoiceNumber: false,
  }));
};

/**
 * Retrieves Entrata vendors data from Firestore doc.
 */
export const getEntrataVendors = async () => {
  const docData = await getDoc(firestoreDocRefs.vendorCodes);
  return docData.get('codes') as VendorData[];
};

/**
 * Retrieves existing property mappings data from Firestore doc.
 */
export const getPropertyMappings = async () => {
  const docData = await getDoc(firestoreDocRefs.propertyMappings);
  return docData.get('mappings') as PropertyCodeData[];
};

/**
 * Retrieves existing vendor mappings data from Firestore doc.
 */
export const getVendorMappings = async () => {
  const docData = await getDoc(firestoreDocRefs.vendorMappings);
  return docData.get('mappings') as VendorMapping[];
};

/**
 * Given mapped prop codes, add those prop code mappings to Firestore.
 * @param paginatedNewMappingsRequired new prop code mappings
 */
export const addPropCodeMappingsToFirestore = async (paginatedNewMappingsRequired: PropertyCodeData[]) => {
  const existingMappings = await getPropertyMappings();
  const updatedMappings = [...existingMappings, ...paginatedNewMappingsRequired];
  await updateDoc(firestoreDocRefs.propertyMappings, { mappings: updatedMappings });
};

/**
 * Given mapped unit codes, add those unit code mappings to Firestore property mappings doc.
 * @param paginatedUnitCodeMappings new unit code mappings
 * @param alert alert context
 */
export const addUnitCodeMappingsToFirestore = async (
  paginatedUnitCodeMappings: UnitData[],
  alert: AlertHelper | null,
) => {
  // get existing mappings
  const mappings = await getPropertyMappings();
  // we want to add the new unit mapping under each respective property code
  paginatedUnitCodeMappings.forEach((newMapping) => {
    const { yardiPropertyCode, yardiUnitCode, entrataUnitData } = newMapping;
    // find related property mapping
    const relevantMapping = mappings.find((map) => map.yardiPropertyCode === yardiPropertyCode);
    if (!relevantMapping) throw alertError(alert, 'Cannot add unit code mapping to unmapped property code');
    // add new unit mapping to existing property mapping
    relevantMapping.units.push({ yardiUnitCode, entrataUnitCode: entrataUnitData.unitId });
  });
  await updateDoc(firestoreDocRefs.propertyMappings, { mappings });
};

/**
 * Given mapped vendor names, add those vendor name mappings to Firestore vendor mappings doc.
 * @param paginatedVendorCodeMappings new vendor name mappings
 */
export const addVendorCodeMappingsToFirestore = async (paginatedVendorCodeMappings: VendorMatch[]) => {
  const existingMappings = await getVendorMappings();

  const newMappings = paginatedVendorCodeMappings.reduce((acc, mapping) => {
    // find vendor in existing mappings to see if vendor has already been mapped at least once
    const vendorInExistingMappings = existingMappings.find(
      (existingMapping) => existingMapping.entrataVendor?.vendorId === mapping.entrataVendor?.vendorId,
    );
    // if the vendor has been mapped previously, we just push the new vendor name to the existing mapping's vendor name array
    if (vendorInExistingMappings) {
      vendorInExistingMappings.csvVendorName.push(mapping.csvVendorName);
      return acc;
    }
    // if there are two mappings to the same vendor in the same batch, merge them in the acc
    const vendorInBatch = acc.find((accMap) => accMap.entrataVendor?.vendorId === mapping.entrataVendor?.vendorId);
    if (vendorInBatch) vendorInBatch.csvVendorName.push(mapping.csvVendorName);
    // if the vendor hasn't been mapped previously (either in Firestore or in the batch), we create a new mapping object
    else acc.push({ ...mapping, csvVendorName: [mapping.csvVendorName] });
    return acc;
  }, [] as VendorMapping[]);

  const updatedMappings = [...existingMappings, ...newMappings];
  await updateDoc(firestoreDocRefs.vendorMappings, { mappings: updatedMappings });
};

/**
 * Retrieves the already selected unit codes for a particular property code within a given paginated batch.
 * @param paginatedUnitCodeMaps paginated batch of unit code mappings
 * @param entrataPropertyCode the property code of the current unit we are trying to map
 */
export const getUsedUnitCodesInThisBatch = (
  paginatedUnitCodeMaps: UnitData[],
  entrataPropertyCode: string,
) => paginatedUnitCodeMaps
// we filter the batch for any other unit mappings under the property code our unit is under, and if those mappings
// have a unit selected i.e. unit is not falsy, we return those
  .filter((map) => map.entrataPropertyCode === entrataPropertyCode && map.entrataUnitData.unitId)
// extract the unitId
  .map((map) => map.entrataUnitData.unitId);

/**
 * Retrieves all unit codes already mapped to the given property in Firestore.
 * @param existingPropertyMappings existing property mappings in Firestore
 * @param entrataPropertyCode the property code from which we are pulling units
 * @param alert alert context
 */
export const getUnitsAlreadyMappedToProperty = (
  existingPropertyMappings: PropertyCodeData[],
  entrataPropertyCode: string,
  alert: AlertHelper | null,
) => {
  // find the mapped property in firestore that corresponds to this parent property of this unit
  const matchingPropertyInFirestore = existingPropertyMappings.find(
    (mapping) => mapping.entrataPropertyCode === entrataPropertyCode,
  );
  // throw err if parent property is not mapped in Firestore - unit mappings can only occur AFTER parent is mapped
  if (!matchingPropertyInFirestore) {
    throw alertError(alert, `Property ${entrataPropertyCode} is not mapped in Firestore`);
  }
  // get all codes from all units already mapped under this property
  return matchingPropertyInFirestore.units.map((unit) => unit.entrataUnitCode);
};

/**
 * Sorts Entrata unit numbers numerically.
 * @param unitCodes Entrata unit numbers (display numbers)
 */
export const sortUnitCodeOptions = (unitCodes: EntrataUnitData[]) => unitCodes.sort(
  (a, b) => parseInt(a.unitNumber, 10) - parseInt(b.unitNumber, 10),
);

/**
 * Calculate the appropriate unit code options for unit code selectors.
 * @param paginatedUnitCodeMaps current batch of unit code mappings
 * @param entrataPropertyCode property code that we should be pulling unit code options for
 * @param entrataUnitCodes list of unit code options for various properties
 * @param existingPropertyMappings existing property/unit code mappings (from Firestore)
 * @param alert alert context
 */
export const getUnitCodeOptions = (
  paginatedUnitCodeMaps: UnitData[],
  entrataPropertyCode: string,
  entrataUnitCodes: EntrataUnitCodeMapping | null,
  existingPropertyMappings: PropertyCodeData[],
  alert: AlertHelper | null,
) => {
  // if unit codes have not yet been retrieved (and thus are null), return an empty array
  if (!entrataUnitCodes) return [];
  // get list of unit codes (for this property) already selected by user in current paginated batch
  const usedUnitCodesForThisPropertyInBatch = getUsedUnitCodesInThisBatch(paginatedUnitCodeMaps, entrataPropertyCode);
  // get list of unit codes already mapped to this property in Firestore
  const usedUnitCodesForThisPropertyInFirestore = getUnitsAlreadyMappedToProperty(
    existingPropertyMappings,
    entrataPropertyCode,
    alert,
  );
  // combine both codes that have already been selected in this batch and codes that have already been mapped in firestore
  const usedUnitCodesForThisProperty = [
    ...usedUnitCodesForThisPropertyInFirestore, ...usedUnitCodesForThisPropertyInBatch,
  ];
  // get all unit code options and sort numerically (we are assuming unit numbers will typically be numeric)
  const sortedOptions = sortUnitCodeOptions(entrataUnitCodes[entrataPropertyCode]);
  // filter all options against the above already-used unit codes
  return sortedOptions.filter(({ unitId }) => !usedUnitCodesForThisProperty.includes(unitId));
};

/**
 * Calculate the appropriate property code options for property code selectors.
 * @param currentlySelectedProperty currently selected entrata property in the selector
 * @param unusedEntrataCodesInThisBatch list of all unused entrata property codes in this paginated batch
 * @param existingPropertyMappings existing property code mappings (from Firestore)
 */
export const getPropertyCodeOptions = (
  currentlySelectedProperty: EntrataProperty | undefined,
  unusedEntrataCodesInThisBatch: EntrataProperty[],
  existingPropertyMappings: PropertyCodeData[],
) => {
  // get list of all property codes already mapped in Firestore
  const alreadyMappedPropertyCodes = existingPropertyMappings.map((mapping) => mapping.entrataPropertyCode);
  // if there is an entrataPropertyCode selected by the user in this selector, we want to include that in the options otherwise it will not display
  const options = currentlySelectedProperty
    ? [...unusedEntrataCodesInThisBatch, currentlySelectedProperty]
    : unusedEntrataCodesInThisBatch;
  // we want to omit property codes which have already been mapped in Firestore
  return options.filter((option) => !alreadyMappedPropertyCodes.includes(option.propertyId));
};

/**
 * Returns list of unused property codes in this paginated batch, given the master list of property codes and the current batch of prop code
 * mappings.
 * @param paginatedPropCodeMaps current batch of property code mappings
 * @param entrataPropertyCodes list of all prop codes
 */
export const getUnusedPropCodes = (
  paginatedPropCodeMaps: PropertyCodeData[],
  entrataPropertyCodes: EntrataProperty[],
) => {
  const usedEntrataPropCodesInThisBatch = paginatedPropCodeMaps.map(({ entrataPropertyCode }) => entrataPropertyCode);
  return entrataPropertyCodes.filter(
    (entrataProperty) => !usedEntrataPropCodesInThisBatch.includes(entrataProperty.propertyId),
  );
};

/**
 * Auto-map all properties possible (from the imports CSV) to their Entrata counterparts.
 * @param importsFile imports csv containing yardi property codes
 * @param existingPropertyMappings existing property codes that have been mapped (From Firestore)
 * @param isDivvy whether the dataSource is divvy or ramp
 * @param csvHeaders csv headers for imports files
 * @param entrataPropertyCodes list of all entrata properties
 */
export const autoMapProperties = (
  importsFile: GoogleDriveFileData<CsvRow[]>,
  existingPropertyMappings: PropertyCodeData[],
  isDivvy: boolean,
  csvHeaders: HeadersDoc,
  entrataPropertyCodes: EntrataProperty[],
) => {
  // get list of all unmapped property codes from the CSV
  const unmappedPropertyCodes = getUnmappedPropCodesFromCsv(csvHeaders, isDivvy, importsFile, existingPropertyMappings);
  // iterate over these unmapped codes and see if there is exactly one match in the entrata properties response
  const newAutoMappings: PropertyCodeData[] = unmappedPropertyCodes.reduce((acc, yardiPropertyCode) => {
    const relevantProperties = entrataPropertyCodes.filter((property) => property.yardiCode === yardiPropertyCode);
    // we only want to auto match if there is exactly one match
    const singlePropertyMatch = relevantProperties.length === 1;
    if (singlePropertyMatch) {
      // since there is exactly one match, we can use index 0
      acc.push({ yardiPropertyCode, entrataPropertyCode: relevantProperties[0].propertyId, units: [] });
    }
    return acc;
  }, [] as PropertyCodeData[]);
  // write these auto mappings to Firestore if there are any new ones
  if (newAutoMappings.length) {
    addPropCodeMappingsToFirestore(newAutoMappings)
      .catch((err) => console.error(err));
  }
  // return the new mappings being added to Firestore
  return newAutoMappings;
};

// IDs of drive folders for file upload
export const driveFolderIds = {
  [DataSource.DIVVY]: {
    imagesFolderId: '13t4qixAjPPakiks450UQVyvVLpMG-7pV',
    importsFolderId: '1cd_GAn5vG_jp5en78BD3P4Elvp-JaPeG',
  },
  [DataSource.RAMP]: {
    importsFolderId: '1S3CAZmYAFAdx5EFLUuxe7AbqEKctimNm',
    imagesFolderId: null,
  },
};

/**
 * Returns a url linking to a Drive folder given the folder Id.
 * @param folderId ID of the folder we are generating a link for
 */
export const getDriveUrl = (folderId: string) => `https://drive.google.com/drive/folders/${folderId}`;

/**
 * Watches Firestore doc of Entrata vendors.
 * @param updateEntrataVendors helper to update Entrata vendors
 */
export const watchEntrataVendors = (
  updateEntrataVendors: (data: VendorData[]) => void,
) => onSnapshot((doc(db, 'codes', 'vendorCodes')), (document) => {
  const docData = document.data() as VendorsDoc;
  if (!docData) return;
  updateEntrataVendors(docData.codes);
});
