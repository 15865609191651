import React from 'react';
import { ApplicationContainer } from '../../helper';
import errorRobot from '../../../assets/robots/Error-Robot.svg';
import { ErrorDescription } from './error-description/ErrorDescription';

/**
 * The error page is the catch-all location for error handling within the UI.
 * Make use of the ErrorsAPI. User can send an error report, upon sending
 * they are navigated to the home page and alerted.
 * @constructor
 */
export const ErrorPage = function () {
  return (
    <ApplicationContainer>
      <div className="font-roboto flex flex-col justify-center items-center">
        <ErrorDescription />
        <img className="mt-2 ml-20" width="375" src={errorRobot} alt="A broken robot" />
      </div>
    </ApplicationContainer>
  );
};
