import { doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { IconButton, Switch } from '@mui/material';
import React from 'react';
import { Edit } from '@mui/icons-material';
import { firestoreDocRefs, getVendorMappings } from '../landing-page-api/LandingPageAPI';
import {
  FirestoreCollections,
  FirestoreDocs, VendorData,
  VendorMapping,
  VendorMappingDoc,
} from '../../pages/landing-page/landing-page-types';
import { alertError } from '../error-api/ErrorAPI';
import { AlertHelper } from '../alert-api/AlertAPI';
import { db } from '../../../firebase-config';
import { VendorId } from '../../pages/settings-page/settings-page-types';

/**
 * Given the new boolean value of useOriginalInvoiceNumber and the id of the related vendor, update the value in Firestore.
 * @param newValue updated value of useOriginalInvoiceNumber
 * @param vendor vendor to update
 */
export const updateOriginalInvoiceNumberSetting = async (newValue: boolean, vendor: VendorData) => {
  const vendorMappings = await getVendorMappings();
  const relevantMapping = vendorMappings.find(
    (mapping) => mapping.entrataVendor?.vendorId === vendor.vendorId
          && mapping.entrataVendor?.locationId === vendor.locationId,
  );
  if (!relevantMapping) throw new Error('Cannot find vendor to update');
  relevantMapping.useOriginalInvoiceNumber = newValue;
  await updateDoc(firestoreDocRefs.vendorMappings, { mappings: vendorMappings });
};

/**
 * Generates cols for vendor settings DataGrid.
 * @param alert alert context
 * @param updateFocusedVendor sets focused vendor for editing matches
 */
export const getVendorSettingsColumns = (
  alert: AlertHelper | null,
  updateFocusedVendor: (vendor: VendorId | null) => void,
) : GridColDef[] => [
  {
    headerName: 'Entrata Vendor',
    field: 'entrataVendor',
    valueGetter: (params: GridValueGetterParams<VendorMapping>) => params.row.entrataVendor?.vendorName,
    flex: 1,
  },
  {
    headerName: 'Vendor Name Matches',
    field: 'csvVendorName',
    valueGetter: (params: GridValueGetterParams<VendorMapping>) => params.row.csvVendorName.join(', '),
    flex: 1,
  },
  {
    headerName: 'Edit Matches',
    field: 'editMatches',
    flex: 0.5,
    renderCell: ({ row }: GridRenderCellParams<VendorMapping>) => (
      <IconButton
        onClick={() => {
          updateFocusedVendor(
            {
              vendorId: row.entrataVendor?.vendorId || '',
              locationId: row.entrataVendor?.locationId || '',
            },
          );
        }}
      >
        <Edit />
      </IconButton>
    ),
  },
  {
    headerName: 'Use Original Invoice Number? (Divvy Only)',
    field: 'useOriginalInvoiceNumber',
    valueGetter: (params: GridValueGetterParams<VendorMapping>) => params.row.useOriginalInvoiceNumber,
    renderCell: ({ row }: GridRenderCellParams<VendorMapping>) => {
      const vendor = row.entrataVendor;
      if (!vendor) return null;
      return (
        <Switch
          checked={row.useOriginalInvoiceNumber}
          onChange={(e, v) => {
            updateOriginalInvoiceNumberSetting(v, vendor)
              .then(() => { alert?.alert('Updated useOriginalInvoiceNumber value', 'success'); })
              .catch((err) => { throw alertError(alert, 'Failed to update useOriginalInvoiceNumber value', err); });
          }}
        />
      );
    },
    flex: 1,
  },
];

/**
 * Watcher for vendor mappings doc in Firestore.
 * @param updateVendorMappings helper for setVendorMappings
 */
export const watchVendorMappings = (
  updateVendorMappings: (data: VendorMapping[]) => void,
) => onSnapshot((doc(db, FirestoreCollections.CODE_MAPPINGS, FirestoreDocs.VENDOR_MAPPINGS)), (document) => {
  const docData = document.data() as VendorMappingDoc;
  if (!docData) return;
  updateVendorMappings(docData.mappings);
});

/**
 * Removes csv vendor name from list of vendor name matches for an Entrata vendor.
 * @param vendorMatchName name of vendor to remove from list of matches
 * @param vendorToUpdate the vendor who we are updating
 */
export const removeMatchFromVendor = async (vendorMatchName: string, vendorToUpdate: VendorMapping) => {
  const vendorMappings = await getVendorMappings();
  const relevantMapping = vendorMappings.find(
    ({ entrataVendor }) => entrataVendor?.vendorId === vendorToUpdate.entrataVendor?.vendorId
          && entrataVendor?.locationId === vendorToUpdate.entrataVendor?.locationId,
  );
  if (!relevantMapping) throw new Error('Cannot find vendor to update');
  // make ref of csv vendor name matches list
  const vendorMatches = [...relevantMapping.csvVendorName];
  // find index of vendor name to remove
  const vendorIndexToRemove = vendorMatches.findIndex((vendorName) => vendorName === vendorMatchName);
  // remove vendor name match
  vendorMatches.splice(vendorIndexToRemove, 1);
  // update vendor mapping in firestore with new csv vendor names list
  relevantMapping.csvVendorName = vendorMatches;
  await updateDoc(firestoreDocRefs.vendorMappings, { mappings: vendorMappings });
};

/**
 * Given the vendor id and location id, retrieve the vendor mapping from vendorMappings.
 * @param vendorMappings list of vendors
 * @param focusedVendorId the vendor and location id
 */
export const getVendorFromVendorId = (
  vendorMappings: VendorMapping[],
  focusedVendorId: VendorId,
) => vendorMappings.find(
  (mapping) => mapping.entrataVendor?.vendorId === focusedVendorId?.vendorId
          && mapping.entrataVendor?.locationId === focusedVendorId?.locationId,
);
