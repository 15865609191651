import React from 'react';
import {
  Autocomplete, createFilterOptions, TextField,
} from '@mui/material';
import { VendorMappingRowParams } from '../landing-page-params';
import { VendorData, VendorMatch } from '../landing-page-types';
import { alertError } from '../../../api/error-api/ErrorAPI';
import { useAlert } from '../../../api/alert-api/AlertAPI';

// This is used to ensure the Vendor Autocomplete selector filters user input against the vendorName field.
const filterOptions = createFilterOptions({ stringify: ({ vendorName }: VendorData) => vendorName });

/**
 * Row used to map a Vendor Name from Imports CSV to an Entrata vendor.
 * @param mapping vendor mapping for this row
 * @param entrataVendors list of entrata vendors
 * @param paginatedVendorCodeMaps current batch of paginated vendor code mappings
 * @param existingVendorMappings existing vendor code mappings pulled from Firestore
 * @param updatePaginatedVendorCodeMaps helper to setPaginatedVendorCodeMaps
 */
export const VendorMappingRow = function ({
  mapping,
  entrataVendors,
  paginatedVendorCodeMaps,
  updatePaginatedVendorCodeMaps,
}: VendorMappingRowParams) {
  const { csvVendorName, entrataVendor } = mapping;

  const alert = useAlert();

  /**
   * Helper function to retrieve the relevant vendor mapping object from within paginatedVendorMaps as well
   * as a ref of paginatedVendorMaps.
   */
  const getRelevantVendor = (vendorName: string) => {
    const vendorCodesRef = [...paginatedVendorCodeMaps];
    const relevantMapping = vendorCodesRef.find((mappingRef) => mappingRef.csvVendorName === vendorName);
    if (!relevantMapping) throw alertError(alert, 'Cannot update vendor mapping');
    return { relevantMapping, vendorCodesRef };
  };

  /**
     * Updates user selected Entrata vendor within mapping.
     */
  const updateEntrataVendorCode = (vendorMapping: VendorMatch, value: VendorData | null) => {
    const { relevantMapping, vendorCodesRef } = getRelevantVendor(vendorMapping.csvVendorName);
    relevantMapping.entrataVendor = value;
    updatePaginatedVendorCodeMaps(vendorCodesRef);
  };

  /**
   * Renders vendor option in Autocomplete selector.
   */
  const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, vendor: VendorData) => {
    const {
      vendorName, locationId, vendorId, vendorCode,
    } = vendor;
    let optionText = `${vendorName} (${locationId})`;
    // add vendor code if there is one
    if (vendorCode) optionText += ` (${vendorCode})`;
    // vendorId technically is not unique in the current options, but vendorId-locationId is
    const key = `${vendorId}-${locationId}`;
    return (<li {...props} key={key}>{optionText}</li>);
  };

  return (
    <div className="flex flex-row items-center my-4">
      <div className="w-1/4">{csvVendorName}</div>
      {/* Select vendor code */}
      <div className="w-1/4">
        <Autocomplete
          options={entrataVendors}
          loading={!entrataVendors.length}
          loadingText="Loading..."
          value={entrataVendor}
          filterOptions={filterOptions}
          getOptionLabel={(option) => option.vendorName}
          renderOption={renderOption}
          onChange={(e, value) => updateEntrataVendorCode(mapping, value)}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Entrata Vendor Name" />}
        />
      </div>
    </div>
  );
};
